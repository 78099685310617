<template>
    <div>
        <transition name="vload">
            <div v-show="isLoading" class="Loader">
              <div class="sk-cube-grid">
                <div class="sk-cube sk-cube1"></div>
                <div class="sk-cube sk-cube2"></div>
                <div class="sk-cube sk-cube3"></div>
                <div class="sk-cube sk-cube4"></div>
                <div class="sk-cube sk-cube5"></div>
                <div class="sk-cube sk-cube6"></div>
                <div class="sk-cube sk-cube7"></div>
                <div class="sk-cube sk-cube8"></div>
                <div class="sk-cube sk-cube9"></div>
              </div>
              <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
            </div>
        </transition>
        <div id="main-wrapper">
            <Header></Header>
            <!-- ============================ Header Menu End ================================== -->
          <div class="fv-area loopSlide">
            <!--広告：2箇所変えるのを忘れない → 2024.6 2箇所設置してあったものを1箇所変更で済むcloneNodeに変更　 -->

              <ul id="carousel">
                <li class="companyListThumb">
                  <router-link to="/companylist" class="image-link">
                    <img src="assets/img/CompanyListBanner.png" class="home-top-img" alt="企業リストページへのリンク画像" width="336" height="220" />
                  </router-link>
                </li>
                <!--<li class="companyListThumb">
                  <a target="_blank" href="https://forms.gle/65T8eXEQ15SHjrSN7" class="image-link">
                    <img src="assets/img/MarketerRecruitBanner.png" class="home-top-img" alt="マーケター募集バナー画像" />
                  </a>
                </li>-->
                <li class="companyListThumb">
                  <router-link to="/register" class="image-link">
                    <img src="assets/img/RecommendBanner.png" class="home-top-img" alt="企業アカウントの解説を進める画像" width="336" height="220" />
                  </router-link>
                </li>
                <!--<li class="companyListThumb">
                  <a target="_blank" href="https://forms.gle/xCkGCffvauSSQJEP7" class="image-link">
                    <img src="assets/img/RequestBanner.png" class="home-top-img" alt="機能改善募集のリンク画像" width="336" height="220" />
                  </a>
                </li>-->
                <li class="companyListThumb">
                  <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6"  class="image-link">
                    <img src="assets/img/ArticleAds2.png" class="home-top-img" alt="記事内広告募集のリンク画像" width="336" height="220" />
                  </a>
                </li>
                <li class="companyListThumb">
                  <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6" class="image-link">
                    <img src="assets/img/AdsBanner.png" class="home-top-img" alt="広告掲載募集のリンク画像" width="336" height="220" />
                  </a>
                </li>
              </ul>
            <!--
            2箇所変更していた時のソース
            <ul>
              <li class="companyListThumb">
                <router-link to="/companylist" class="image-link">
                  <img src="assets/img/CompanyListBanner.png" class="home-top-img" alt="企業リストページへのリンク画像" decoding="async" width="336" height="220" />
                </router-link>
              </li>
              <li class="companyListThumb">
                <a target="_blank" href="https://forms.gle/65T8eXEQ15SHjrSN7" class="image-link">
                  <img src="assets/img/MarketerRecruitBanner.png" class="home-top-img" alt="マーケター募集バナー画像" />
                </a>
              </li>
              <li class="companyListThumb">
                <router-link to="/register" class="image-link">
                  <img src="assets/img/RecommendBanner.png" class="home-top-img" alt="企業アカウントの解説を進める画像" decoding="async" width="336" height="220" />
                </router-link>
              </li>
              <li class="companyListThumb">
                <a target="_blank" href="https://forms.gle/xCkGCffvauSSQJEP7" class="image-link">
                  <img src="assets/img/RequestBanner.png" class="home-top-img" alt="機能改善募集のリンク画像" decoding="async" width="336" height="220" />
                </a>
              </li>
              <li class="companyListThumb">
                <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6"  class="image-link">
                  <img src="assets/img/ArticleAds2.png" class="home-top-img" alt="記事内広告募集のリンク画像" decoding="async" width="336" height="220" />
                </a>
              </li>
              <li class="companyListThumb">
                <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6" class="image-link">
                  <img src="assets/img/AdsBanner.png" class="home-top-img" alt="広告掲載募集のリンク画像" decoding="async" width="336" height="220" />
                </a>
              </li>
            </ul>-->
          </div>
          <!-- ============================ Page Title End ================================== -->
          <section class="min-sec pt40-only">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12">
                  <div class="home-content">
                  <h2 class="home-h2">おすすめの記事</h2>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12" v-for="item in this.pickupItems" :key="item.id">
                          <div class="item">
                            <div class="smart-testimonials">
                              <router-link :to="`/article/${encodeURI(item.englishTitle)}`">
                                <div class="smart-testi-thumb">
                                  <!--<img :src="item.thumbnail" class="img-fluid" :alt="item.alt">-->
                                  <picture class="img-fluid w-100">
                                    <source media="(min-width: 768px)" type="image/avif" :srcset="item.thumb_avif" width="333" height="188">
                                    <source type="image/webp" :srcset="item.thumb_webp"  width="333" height="188">
                                    <img :src="item.thumbnail" class="img-fluid w-100" :alt="item.alt" width="333" height="188">
                                  </picture>

                                </div>
                              </router-link>
                              <div class="blog-info">
                                <span class="post-date">公開日：{{item.createdAt}}</span>
                                <span class="post-date">更新日：{{item.updatedAt}}</span>
                              </div>
                              <div class="smart-testimonials-content smart-testimonials-content-home">
                                <div class="smart-tes-content">
                                  <p><router-link :to="`/article/${encodeURI(item.englishTitle)}`">{{item.title}}</router-link></p>
                                </div>
                              </div>
                              <div class="blog-cates">
                                <ul>
                                  <li v-for="tag in item.tags" :key="item.id + tag.sys.id"><router-link :to="{ path: '/article', query: { tag: encodeURI(tag.sys.id) }}" class="blog-cates-list">{{allTags[tag.sys.id]}}</router-link></li>
                                </ul>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="home-more">
                      <router-link :to="{ path: '/article', query: { recommend: true.toString() }}">
                        おすすめの記事をもっとみる
                      </router-link>
                    </div>
                  </div>
                  <div class="home-content padding-top-40 margin-top-40">
                    <h2 class="home-h2">最近投稿された記事</h2>
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12" v-for="item in this.recentlyItems" :key="item.id">
                          <div class="item">
                            <div class="smart-testimonials">
                              <router-link :to="`/article/${encodeURI(item.englishTitle)}`">
                                <div class="smart-testi-thumb">
                                  <!--<img :src="item.thumbnail" class="img-fluid" :alt="item.alt">-->
                                  <picture class="img-fluid w-100">
                                    <source media="(min-width: 768px)" type="image/avif" :srcset="item.thumb_avif" width="333" height="188">
                                    <source type="image/webp" :srcset="item.thumb_webp"  width="333" height="188">
                                    <img :src="item.thumbnail" class="img-fluid w-100" :alt="item.alt" width="333" height="188">
                                  </picture>

                                </div>
                              </router-link>
                              <div class="blog-info">
                                <span class="post-date"><i class="fa fa-calendar"></i>{{item.createdAt}}</span>
                                <span class="post-date"><i class="fa fa-refresh"></i>{{item.updatedAt}}</span>
                              </div>
                              <div class="smart-testimonials-content smart-testimonials-content-home">
                                <div class="smart-tes-content">
                                  <p><router-link :to="`/article/${encodeURI(item.englishTitle)}`">{{item.title}}</router-link></p>
                                </div>
                              </div>
                              <div class="blog-cates">
                                <ul>
                                  <li v-for="tag in item.tags" :key="item.id + tag.sys.id"><router-link :to="{ path: '/article', query: { tag: encodeURI(tag.sys.id) }}" class="blog-cates-list">{{allTags[tag.sys.id]}}</router-link></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="home-more">
                      <router-link :to="`/article`">
                        最近投稿された記事をもっとみる
                      </router-link>
                    </div>
                  </div>
<!--
                  <div class="home-content">
                    <h2 class="home-h2">おすすめのインタビュー記事</h2>
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12" v-for="item in this.pickupInterviewItems" :key="item.id">
                          <div class="item">
                            <div class="smart-testimonials">
                              <router-link :to="`/interview/${encodeURI(item.englishTitle)}`">
                                <div class="smart-testi-thumb">
                                  <picture class="img-fluid w-100">
                                    <source media="(min-width: 768px)" type="image/avif" :srcset="item.thumb_avif" width="333" height="188">
                                    <source type="image/webp" :srcset="item.thumb_webp"  width="333" height="188">
                                    <img :src="item.thumbnail" class="img-fluid w-100" :alt="item.alt" width="333" height="188">
                                  </picture>

                                </div>
                              </router-link>
                              <div class="blog-info">
                                <span class="post-date">公開日：{{item.createdAt}}</span>
                                <span class="post-date">更新日：{{item.updatedAt}}</span>
                              </div>
                              <div class="smart-testimonials-content smart-testimonials-content-home">
                                <div class="smart-tes-content">
                                  <p><router-link :to="`/interview/${encodeURI(item.englishTitle)}`">{{item.title}}</router-link></p>
                                </div>
                              </div>
                              <div class="blog-cates">
                                <ul>
                                  <li v-for="tag in item.tags" :key="item.id + tag.sys.id"><router-link :to="{ path: '/interview', query: { tag: encodeURI(tag.sys.id) }}" class="blog-cates-list">{{allTags[tag.sys.id]}}</router-link></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="home-more">
                      <router-link :to="{ path: '/interview', query: { recommend: true.toString() }}">
                        おすすめのインタビュー記事をもっとみる
                      </router-link>
                    </div>
                  </div>
                  <div class="home-content padding-top-40 margin-top-40">
                    <h2 class="home-h2">最近投稿されたインタビュー記事</h2>
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12" v-for="item in this.recentlyInterviewItems" :key="item.id">
                          <div class="item">
                            <div class="smart-testimonials">
                              <router-link :to="`/interview/${encodeURI(item.englishTitle)}`">
                                <div class="smart-testi-thumb">
                                  <picture class="img-fluid w-100">
                                    <source media="(min-width: 768px)" type="image/avif" :srcset="item.thumb_avif" width="333" height="188">
                                    <source type="image/webp" :srcset="item.thumb_webp"  width="333" height="188">
                                    <img :src="item.thumbnail" class="img-fluid w-100" :alt="item.alt" width="333" height="188">
                                  </picture>

                                </div>
                              </router-link>
                              <div class="blog-info">
                                <span class="post-date"><i class="fa fa-calendar"></i>{{item.createdAt}}</span>
                                <span class="post-date"><i class="fa fa-refresh"></i>{{item.updatedAt}}</span>
                              </div>
                              <div class="smart-testimonials-content smart-testimonials-content-home">
                                <div class="smart-tes-content">
                                  <p><router-link :to="`/interview/${encodeURI(item.englishTitle)}`">{{item.title}}</router-link></p>
                                </div>
                              </div>
                              <div class="blog-cates">
                                <ul>
                                  <li v-for="tag in item.tags" :key="item.id + tag.sys.id"><router-link :to="{ path: '/interview', query: { tag: encodeURI(tag.sys.id) }}" class="blog-cates-list">{{allTags[tag.sys.id]}}</router-link></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="home-more">
                      <router-link :to="`/interview`">
                        最近投稿されたインタビュー記事をもっとみる
                      </router-link>
                    </div>
                  </div>
-->
                </div>
                <side-area :tags="allTags"></side-area>
              </div>
            </div>
          </section>
            <!-- ========================== Blog Detail Elements ============================= -->
            <Footer></Footer>
        </div> 
        <!-- ============================================================== -->
        <!-- End Wrapper -->
        <!-- ============================================================== -->
    </div>
</template>
<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import SideArea from "@/views/components/SideArea";
    import moment from "moment";
    import MixIn from "@/common/mixin";
    import {createContentfulClient} from "@/common/contentful";
    import Vue from 'vue'

    export default {
        name: 'Home',
        components: {Footer, Header, SideArea},
        mixins: [MixIn],
        data: function () {
            return {
              pickupItems: [],
              recentlyItems: [],
              pickupInterviewItems: [],
              recentlyInterviewItems: [],
              isLoading: true,
              allTags: {}
            }
        },
        created: function () {
            if (!Vue.prototype.$contentfulClient) {
                Vue.prototype.$contentfulClient = createContentfulClient();
            }
            this.loadEntries();
        },
        watch: {},
        beforeMount: function (){
          //carousel
          window.addEventListener('DOMContentLoaded',this.carousel);
          this.$nextTick(() => {
            this.carousel();
          });

        },
        methods: {
            async loadEntries() {
              const client = Vue.prototype.$contentfulClient;
              {
                const query = {
                  limit: 6,
                  "content_type": "blog",
                  'fields.pickup[in]': true,
                };

                const entries = await client.getEntries(query);

                const allTags = await client.getTags();
                this.allTags = Object.fromEntries(allTags.items.map(item => {
                  return [item.sys.id, item.name]
                }));

                this.pickupItems = entries.items.map(item => {
                  return {
                    id: item.sys.id,
                    title: item.fields.title,
                    englishTitle: item.fields.englishTitle,
                    thumbnail: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url : "",
                    //alt: item.fields.thumbnail ? item.fields.thumbnail.fields.description : "",
                    tags: item.metadata.tags,
                    createdAt: moment(item.sys.createdAt).format("YYYY/MM/DD"),
                    updatedAt: moment(item.sys.updatedAt).format("YYYY/MM/DD"),

                    //add.2024.06
                    alt: item.fields.thumbnail ? item.fields.title  : "",
                    thumb_avif: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=avif&q=50' : "" ,
                    thumb_webp: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=webp&q=50' : "" ,
                    thumb_jpg: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=jpg&fl=progressive&q=50' : "" ,
                  }
                });
              }
              {
                const query = {
                  limit: 6,
                  "content_type": "blog",
                  order: '-sys.createdAt'
                };
                const entries = await client.getEntries(query);

                // console.log(JSON.stringify(entries));

                this.recentlyItems = entries.items.map(item => {
                  return {
                    id: item.sys.id,
                    title: item.fields.title,
                    englishTitle: item.fields.englishTitle,
                    thumbnail: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url : "",
                    //alt: item.fields.thumbnail ? item.fields.thumbnail.fields.description : "",
                    tags: item.metadata.tags,
                    createdAt: moment(item.sys.createdAt).format("YYYY/MM/DD"),
                    updatedAt: moment(item.sys.updatedAt).format("YYYY/MM/DD"),

                    //add.2024.06
                    alt: item.fields.thumbnail ? item.fields.title  : "",
                    thumb_avif: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=avif&q=50' : "" ,
                    thumb_webp: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=webp&q=50' : "" ,
                    thumb_jpg: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=jpg&fl=progressive&q=50' : "" ,
                  }
                });
              }
              {
                const query = {
                  limit: 6,
                  "content_type": "interview",
                  'fields.pickup[in]': true,
                };

                const entries = await client.getEntries(query);

                const allTags = await client.getTags();
                this.allTags = Object.fromEntries(allTags.items.map(item => {
                  return [item.sys.id, item.name]
                }));

                this.pickupInterviewItems = entries.items.map(item => {
                  return {
                    id: item.sys.id,
                    title: item.fields.title,
                    englishTitle: item.fields.englishTitle,
                    thumbnail: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url : "",
                    //alt: item.fields.thumbnail ? item.fields.thumbnail.fields.description : "",
                    tags: item.metadata.tags,
                    createdAt: moment(item.sys.createdAt).format("YYYY/MM/DD"),
                    updatedAt: moment(item.sys.updatedAt).format("YYYY/MM/DD"),

                    //add.2024.06
                    alt: item.fields.thumbnail ? item.fields.title  : "",
                    thumb_avif: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=avif&q=50' : "" ,
                    thumb_webp: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=webp&q=50' : "" ,
                    thumb_jpg: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=jpg&fl=progressive&q=50' : "" ,
                  }
                });
              }
              {
                const query = {
                  limit: 6,
                  "content_type": "interview",
                  order: '-sys.createdAt'
                };
                const entries = await client.getEntries(query);

                // console.log(JSON.stringify(entries));

                this.recentlyInterviewItems = entries.items.map(item => {
                  return {
                    id: item.sys.id,
                    title: item.fields.title,
                    englishTitle: item.fields.englishTitle,
                    thumbnail: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url : "",
                    //alt: item.fields.thumbnail ? item.fields.thumbnail.fields.description : "",
                    tags: item.metadata.tags,
                    createdAt: moment(item.sys.createdAt).format("YYYY/MM/DD"),
                    updatedAt: moment(item.sys.updatedAt).format("YYYY/MM/DD"),

                    //add.2024.06
                    alt: item.fields.thumbnail ? item.fields.title  : "",
                    thumb_avif: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=avif&q=50' : "" ,
                    thumb_webp: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=webp&q=50' : "" ,
                    thumb_jpg: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=jpg&fl=progressive&q=50' : "" ,
                  }
                });
              }
              this.isLoading = false;
            },
            carousel(){
              let carousel = document.querySelector("#carousel");
              let clone_element = carousel.cloneNode(true);
              carousel.after(clone_element);
            }
        }
    }
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .vload-leave-active {
        transition: opacity 350ms;
    }

    .vload-leave-to {
        opacity: 0;
    }
</style>
